import { motion } from "framer-motion";
import axios from "axios";
import { ChangeEvent, useEffect, useState } from "react";
        




const TradingBot = () => {


  const [asset,setAsset] = useState("");
  const [orderType, setOrderType] = useState("buy");
  const [basePrice,setBasePrice] = useState<string | number>("");
  const [limitPrice,setLimitPrice] = useState<string | number>("");
  const [orderCount,setOrderCount] = useState<string | number>("");
  const [quantity,setQuantity] = useState<string | number>("");

  const [tradeLoading,setTradeLoading] = useState(false);
  const [basePriceLoading,setBasePriceLoading] = useState(false);

  const [all_assets, set_all_assets] = useState([""]);


    const startBot = async()=>{
    setTradeLoading(true);
     await axios
      .post("https://chambsexchange.onrender.com/api/spot/trade-bot", {
        asset: asset,
        orderType: orderType,
        basePrice: basePrice,
        limitPrice: limitPrice,
        orderCount: orderCount,
        quantity: quantity
      })
      .then((response) => {
        setTradeLoading(false)
        console.log("trade bot started:  ",response.data);
      })
      .catch((error) => {
        console.log("error starting bot:  ",error);
      });

    }

    const load_asset = async()=>{
      await axios.get("https://chambsexchange.onrender.com/api/address/all-unique-curerency").then((response)=>{
        console.log("assets loaded",response.data);
        set_all_assets(response.data);
      }).catch((err)=>{
        console.log("Assets loaded: ", err);
      });
    }

    const onAssetBlur = async()=>{
      setBasePriceLoading(true);
      console.log("BasePrice loading")
      await axios.get(`https://chambsexchange.onrender.com/api/spot/prices/${asset}`).then((response)=>{
        console.log("Base price loaded: ",response.data);
        setBasePrice(response.data.currentPrice);
        setLimitPrice(response.data.currentPrice + 0.1)
        setBasePriceLoading(false);
      })
    }

    const assetChange = (event: ChangeEvent<HTMLSelectElement>)=>{
      setAsset(event.target.value);
    }
    const basePriceChange = (event: ChangeEvent<HTMLInputElement>) =>{
      setBasePrice(event.target.value);
    }
    const limitPriceChange = (event: ChangeEvent<HTMLInputElement>) =>{
      setLimitPrice(event.target.value);
    }
    const orderCountChange = (event: ChangeEvent<HTMLInputElement>) =>{
      setOrderCount(event.target.value);
    }
    const quantityChange = (event: ChangeEvent<HTMLInputElement>) =>{
      setQuantity(event.target.value);
    }



    
    

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow pt-16 text-white pb-16 overflow-y-auto p-8 min-h-screen">
        <div className="bg-white flex justify-between items-center rounded-full text-xl font-semibold">


        <div onClick={()=>{setOrderType("buy")}} className={`${orderType == "buy"? "bg-green-500": "bg-transparent"} h-full py-2 m-1 px-10 rounded-full`}>
          <motion.button
            whileHover={{
              scale: 1.1,
              color: "#000",
            }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300 }}
            className={`mr-2 ${orderType == "buy"? "text-white": "text-green-600"} text-2xl font-semibold`}
          >
            Buy
          </motion.button>
        </div>

        <div onClick={()=>{setOrderType("sell")}} className= {`${orderType == "sell"? "bg-red-500": "bg-transparent"} h-full py-2 m-1 px-10 rounded-full`}>
          <motion.button
            whileHover={{
              scale: 1.1,
              color: "#000",
            }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300 }}
            className={`mr-2 ${orderType == "sell"? "text-white": "text-red-600"} text-2xl font-semibold`}
          >
            Sell
          </motion.button>
        </div>

        </div>

        <div className="flex mt-2 justify-between">
          <div className="w-full">
            <h1 className="text-black py-2">Select Pairs</h1>
            <select
              name=""
              id="" onFocus={load_asset} onChange={assetChange} value={asset} onBlur={onAssetBlur}
              className="bg-transparent w-full border rounded-md text-black py-3 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              <option value="" className="text-black" disabled selected>
                Select asset
              </option>
              <>
              {
                  all_assets.map((item,index)=>(
                    <option value={item} className="text-black">{item}</option>
                  ))
              }
              </>
            </select>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex mt-2 justify-between">
            <div className="w-full">
              <h1 className="text-black text-2xl font-semibold py-2">
                Base Price
              </h1>
              <input
                type="text" value={basePriceLoading == true? `${asset} price loading...`: basePrice} onChange={basePriceChange} min={0}
                placeholder="search for price here..." disabled
                className="w-full text-black py-2 rounded-md bg-transparent border focus:outline-none focus:ring-2 focus:ring-green-500 placeholder:p-2"
              />
            </div>
          </div>
          <div className="flex mt-2 justify-between">
            <div className="w-full">
              <h1 className="text-black text-2xl font-semibold py-2">Price</h1>
              <input
                type="number" min={0}
                placeholder="enter your price here" value={limitPrice} onChange={limitPriceChange}
                className="w-full text-black py-2 p-2 rounded-md bg-transparent border focus:outline-none focus:ring-2 focus:ring-green-500 placeholder:p-0"
              />
            </div>
          </div>
          <div className="flex mt-2 justify-between">
            <div className="w-full">
              <h1 className="text-black text-2xl font-semibold py-2">
                Numbers of Trades
              </h1>
              <input
                type="number" min={0}
                placeholder="enter your price here" value={orderCount} onChange={orderCountChange}
                className="w-full p-2 py-2 rounded-md bg-transparent text-black border focus:outline-none focus:ring-2 focus:ring-green-500 placeholder:p-0"
              />
            </div>
          </div>
          <div className="flex mt-2 justify-between">
            <div className="w-full">
              <h1 className="text-black text-2xl font-semibold py-2">
                Quantity
              </h1>
              <input
                type="number" min={0}
                placeholder="Select quantity" value={quantity} onChange={quantityChange}
                className="w-full p-2 py-2 rounded-md bg-transparent text-black border focus:outline-none focus:ring-2 focus:ring-green-500 placeholder:p-0"
              />
            </div>
          </div>
          <div className="flex mt-2 justify-between">
            {
            tradeLoading == false?
            (<div className={`w-full`} onClick={startBot}>
              <motion.button
                whileHover={{
                  scale: 1.05,
                  backgroundColor: "#006400",
                  color: "#fff",
                }}
                whileTap={{ scale: 0.95 }}
                transition={{ type: "spring", stiffness: 300 }}
                className={`${orderType == "buy"? "bg-green-500": "bg-red-500"} w-full mt-2 py-3 rounded-lg font-bold`}
              >
                Execute Trade
              </motion.button>
            </div>):

            (<div className={`w-full`}>
              <motion.button
                whileHover={{
                  scale: 1.05,
                  backgroundColor: "#006400",
                  color: "#fff",
                }}
                whileTap={{ scale: 0.95 }}
                transition={{ type: "spring", stiffness: 300 }}
                className={`${orderType == "buy"? "bg-green-500": "bg-red-500"} w-full mt-2 py-3 rounded-lg font-bold`}
                >
                  Executing {orderType} trade
              </motion.button>
            </div>)


            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingBot;
